export enum ImageMode {
    BLACK_AND_WHITE = "greyscale",
    NORMAL = "normal",
    BW_INVERT = "bw_invert",
    DARK_BW = "dark",
}

export type ImageType = {
    src: string;
    x: number;
    y: number;
    scale: number;
};

export enum CustomImageBlendType {
    DEFAULT = "Normal",
    OVERLAY = "Overlay",
}

export type CustomImageContextType = {
    frontImages: ImageType[];
    backImages: ImageType[];
    imageMode: ImageMode;
    blendMode: CustomImageBlendType;
    frontMask: string | null;
    backMask: string | null;
};
