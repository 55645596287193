// src/TShirt.js
'use client'
import Image from "next/image";

type TShirtProps = {
  src: string;
  altText: string
  height: number
  width: number
};

const TShirt = (props: TShirtProps) => {

  return (
    <Image src={props.src}
      alt={props.altText}
      className="object-contain"
      priority={true} loading="eager"
      height={props.height}
      width={props.width}
      onContextMenu={(e) => e.preventDefault()}
    />
  );
};

export default TShirt;
